import React, { useState } from 'react'

import { FormGroup, Textarea } from '@te-digi/styleguide'

const TextareaExample3 = () => {
  const [value, setValue] = useState('')

  return (
    <FormGroup noMargin>
      <Textarea
        required
        label="Label"
        onChange={event => setValue(event.target.value)}
        value={value}
      />
    </FormGroup>
  )
}

export { TextareaExample3 }
