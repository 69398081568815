import React, { useState } from 'react'

import { FormGroup, Textarea } from '@te-digi/styleguide'

const TextareaDescriptionExample = () => {
  const [value1, setValue1] = useState(
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem.'
  )

  const [value2, setValue2] = useState(
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem.'
  )

  const [value3, setValue3] = useState(
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem.'
  )

  return (
    <>
      <FormGroup>
        <Textarea
          counter
          label="Label"
          maxLength={100}
          onChange={event => setValue1(event.target.value)}
          placeholder="Placeholder"
          value={value1}
        />
      </FormGroup>
      <FormGroup>
        <Textarea
          error="Error"
          id="textarea-description-example-3"
          label="Label"
          onChange={event => setValue2(event.target.value)}
          placeholder="Placeholder"
          value={value2}
        />
      </FormGroup>
      <FormGroup noMargin>
        <Textarea
          counter
          label="Label"
          error="Error"
          maxLength={100}
          onChange={event => setValue3(event.target.value)}
          placeholder="Placeholder"
          value={value3}
        />
      </FormGroup>
    </>
  )
}

export { TextareaDescriptionExample }
