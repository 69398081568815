import React from 'react'

import {
  FormGroup,
  List,
  ListItem,
  Paragraph,
  Textarea
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Color } from '../../components/Color'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { TextareaAddonExample } from '../../examples/react/TextareaAddonExample'
import { TextareaDescriptionExample } from '../../examples/react/TextareaDescriptionExample'
import { TextareaExample1 } from '../../examples/react/TextareaExample1'
import { TextareaExample2 } from '../../examples/react/TextareaExample2'
import { TextareaExample3 } from '../../examples/react/TextareaExample3'
import { TextFieldCharacters } from '../../components/TextFieldCharacters'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Textarea"
    components={[{ component: Textarea, restElement: 'TextareaAutosize' }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Playground
        example={TextareaExample1}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div className="form-group mb-0">
          <LabelHTMLExample htmlFor="textarea-html-example-1">
            Label
          </LabelHTMLExample>
          <textarea
            className="form-control"
            id="textarea-html-example-1"
            placeholder="Placeholder"
            rows={4}
          />
        </div>
      </Playground>
    </Section>
    <Section title="Pakollisuus">
      <Playground
        example={TextareaExample3}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div className="form-group mb-0">
          <LabelHTMLExample
            htmlFor="textarea-html-required-example"
            required
          >
            Label
          </LabelHTMLExample>
          <textarea
            className="form-control"
            id="textarea-html-required-example"
            aria-required
            rows={4}
          />
        </div>
      </Playground>
    </Section>

    <Section title="Koko">
      <Paragraph>
        <Code>Textarea</Code>-komponentista on myös <Code>lg</Code>-koko.
        Komponentin korkeuden voi määritellä myös kasvamaan automaattisesti
        sisällön mukaan.
      </Paragraph>
      <Playground
        example={TextareaExample2}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Disabloitu">
      <Playground WrapperComponent={FormLayoutWrapper}>
        <>
          <FormGroup>
            <Textarea
              disabled
              label="Label"
              resize="none"
              value="Lorem ispum dolor sit amet"
            />
          </FormGroup>
          <FormGroup noMargin>
            <Textarea
              disabled
              label="Label"
              placeholder="Placeholder"
              resize="none"
            />
          </FormGroup>
        </>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <div className="form-group">
            <LabelHTMLExample
              disabled
              htmlFor="textarea-html-disabled-value-example"
            >
              Label
            </LabelHTMLExample>
            <textarea
              className="form-control resize-none"
              disabled
              id="textarea-html-disabled-value-example"
              rows={4}
              value="Lorem ispum dolor sit amet"
            />
          </div>
          <div className="form-group mb-0">
            <LabelHTMLExample
              disabled
              htmlFor="textarea-html-disabled-placeholder-example"
            >
              Label
            </LabelHTMLExample>
            <textarea
              className="form-control resize-none"
              disabled
              id="textarea-html-disabled-placeholder-example"
              placeholder="Placeholder"
              rows={4}
            />
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Virheviesti ja merkkilaskuri">
      <List variant="unordered">
        <ListItem>
          <Code>Textarea</Code>-komponentin alapuolella oleva tila on varattu
          virheviestille ja merkkilaskurille.
        </ListItem>
        <ListItem>
          Virheviestiä käyttäessä komponentin reunaviiva värjäytyy{' '}
          <Color color="danger" /> värillä.
        </ListItem>
        <ListItem>
          Komponenttiin syötettävää merkkimäärää voi rajoittaa. Ohjelmallisesti
          annettu merkkimäärän ylittävä arvo typistetään lopusta
          maksimimerkkimäärään.
        </ListItem>
        <ListItem>
          Merkkilaskuri ilmestyy näkyviin, kun tekstiä on syötetty 80 %
          merkkimäärästä. Kynnystä on tarvittaessa mahdollista muuttaa.
        </ListItem>
        <ListItem>
          Komponentti typistää mahdollisesti liian pitkän liitetyn tekstin ja
          näyttää virheilmoituksen.
        </ListItem>
      </List>
      <Playground
        example={TextareaDescriptionExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Lisäosat">
      <Paragraph>
        <Code>Textarea</Code>-komponentille on mahdollista määritellä alkuun
        tuleva lisäkomponentti.
      </Paragraph>
      <Playground
        example={TextareaAddonExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div className="input-group">
          <div className="input-group-prepend">
            <div
              aria-label="Suomeksi"
              className="input-group-text"
              id="textarea-html-addon-example-input-group"
            >
              FI
            </div>
          </div>
          <textarea
            aria-describedby="textarea-html-addon-example-input-group"
            className="form-control"
            rows={4}
          />
        </div>
      </Playground>
    </Section>
    <Section title="Sallitut merkit">
      <TextFieldCharacters />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että komponenttia on käytetty tilanteessa, jossa tarvitaan
          pidempi kenttä syötteelle.
        </ListItem>
        <ListItem>
          Jos kentän täyttämiseen liittyy ohjeistus, lisää se sublabelilla ja
          varmista, että se auttaa käyttäjää syöttämään tiedot oikein.
        </ListItem>
        <ListItem>
          Varmista, että virheteksti auttaa käyttäjää korjaamaan virheen.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
