import React from 'react'

import {
  FormGroup,
  InputGroupText,
  Label,
  Stack,
  Textarea
} from '@te-digi/styleguide'

const TextareaAddonExample = () => (
  <FormGroup
    ariaLabelledby="textarea-prepend-example-label"
    noMargin
  >
    <Label
      as="p"
      id="textarea-prepend-example-label"
    >
      Label
    </Label>
    <Stack gap="md">
      <Textarea
        addonPrepend={<InputGroupText>FI</InputGroupText>}
        ariaLabel="Label suomeksi"
      />
      <Textarea
        addonPrepend={<InputGroupText>EN</InputGroupText>}
        ariaLabel="Label englanniksi"
        error="Error"
      />
    </Stack>
  </FormGroup>
)

export { TextareaAddonExample }
